import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.assign";

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";
import PatternLayout from "../../components/layouts/pattern-layout";
import Container from "../../../packages/Container/src";
import Div from "../../../packages/Div/src";
import Flex from "../../../packages/Flex/src";
import { Grid, GridItem } from "../../../packages/Grid/src";
import { Heading, P } from "../../../packages/Typography/src";
import Image from "../../../packages/Image/src";
import DataStoryCard from "../../../packages/DataStoryCard/src";
import { DATASTORIES } from "../../constants/patterns";

var TopicPagePattern = function TopicPagePattern() {
  return React.createElement(PatternLayout, null, React.createElement(Grid, {
    alignItems: "center"
  }, React.createElement(GridItem, {
    gridColumn: ["1 / span 12"],
    gridRow: "1"
  }, React.createElement(Container, {
    size: "md"
  }, React.createElement(Flex, null, React.createElement(Div, {
    width: ["100%", "50%"],
    pr: "2.5rem"
  }, React.createElement(Heading, {
    as: "h1"
  }, "Education"), React.createElement(P, {
    size: "lg",
    muted: true
  }, "Datastory visualiserar öppna data och annan information om den svenska skolan för att skapa bättre möjligheter till faktabaserade beslut."))))), React.createElement(GridItem, {
    gridColumn: ["1 / span 12", "7 / span 6"],
    gridRow: ["2", "1"],
    height: "100%"
  }, React.createElement(Div, {
    bg: "orange.500",
    width: "100%",
    height: "100%",
    p: "2.5rem",
    display: "flex",
    alignItems: "center"
  }, React.createElement(Image, {
    src: "https://www.datocms-assets.com/5436/1554380960-swedish-schools-square.svg",
    style: {
      maxHeight: "100%",
      margin: "0 auto"
    }
  })))), React.createElement(Div, {
    bg: "grey.50"
  }, React.createElement(Container, {
    size: "md",
    pt: "2rem",
    pb: "5rem",
    mb: ["2.5rem", "5rem"]
  }, React.createElement(Heading, {
    as: "h2",
    size: "xs",
    textTransform: "uppercase",
    mb: "2rem",
    muted: true
  }, "Recently updated"), React.createElement(Grid, null, DATASTORIES.slice(0, 3).map(function (_ref) {
    var id = _ref.id,
        restProps = _objectWithoutPropertiesLoose(_ref, ["id"]);

    return React.createElement(GridItem, {
      key: id,
      gridColumn: ["span 12", null, "span 4"]
    }, React.createElement(DataStoryCard, Object.assign({
      size: "sm"
    }, restProps)));
  })))), React.createElement(Container, {
    size: "md",
    mb: ["2.5rem", "5rem"]
  }, React.createElement(Heading, {
    as: "h2",
    size: "xs",
    textTransform: "uppercase",
    mb: "2rem",
    muted: true
  }, "Latest data stories"), React.createElement(Grid, null, DATASTORIES.slice(0, 4).map(function (_ref2) {
    var id = _ref2.id,
        restProps = _objectWithoutPropertiesLoose(_ref2, ["id"]);

    return React.createElement(GridItem, {
      key: id,
      gridColumn: ["span 12", "span 6", "span 3"]
    }, React.createElement(DataStoryCard, restProps));
  }))), React.createElement(Container, {
    size: "md",
    mb: ["2.5rem", "5rem"]
  }, React.createElement(Heading, {
    as: "h2",
    size: "xs",
    textTransform: "uppercase",
    mb: "2rem",
    muted: true
  }, "Popular in this topic"), React.createElement(Grid, null, DATASTORIES.slice(0, 3).map(function (_ref3) {
    var id = _ref3.id,
        restProps = _objectWithoutPropertiesLoose(_ref3, ["id"]);

    return React.createElement(GridItem, {
      key: id,
      gridColumn: ["span 12", null, "span 4"]
    }, React.createElement(DataStoryCard, Object.assign({
      size: "sm"
    }, restProps)));
  }))));
};

export default TopicPagePattern;